import React, { useState } from 'react';
import { useLocation, useNavigate  } from 'react-router-dom';
import logo from '../assets/images/2cimple-logo-black.png';
import MenuIcon from './MenuIcon';
import Menu from './Menu';

const Header = ({ onAppBtn, currentPath}) => {
 /*   const navigate = useNavigate();
   const location= useLocation();
   const currentPath =location.pathname; */
   const [isOpen, setIsOpen]=useState(false);
  const toggleMenu=()=>
  {
    setIsOpen(!isOpen)
    console.log(isOpen)
  }
   const on_AppBtn=(event)=>
  {
    onAppBtn(event)
  }

  
  return (
    <div className='header'>
    <div className="header-bar">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <MenuIcon  toggleMenu={toggleMenu} isOpen={isOpen}/>
      
      <ul className="nav" id="desktop-nav" style={{ float: 'right' }}>
       <li className="nav-item">
                    <button id="home" className={`nav-link header-button ${currentPath === '/' ? 'page-selected' : 'anim'}`} onClick={onAppBtn}>Home</button>
                </li>
                <li className="nav-item">
                    <button id="studio" className={`nav-link header-button ${currentPath === '/studio' ? 'page-selected' : 'anim'}`} onClick={currentPath !== '/studio' ? onAppBtn:()=>{}}>Studio</button>
                </li>
                {/* <li className="nav-item">
                    <button id="blog" className={`nav-link header-button ${currentPath === '/blog' ? 'page-selected' : 'anim'}`} onClick={onAppBtn}>Blog</button>
                </li> */}
                <li className="nav-item">
                    <button id="nano" className={`nav-link header-button ${currentPath === '/nano' ? 'page-selected' : 'anim'}`} onClick={onAppBtn}>Nano</button>
                </li>
                <li className="nav-item">
                    <button id="contact-us" className={`nav-link header-button ${currentPath === '/contact-us' ? 'page-selected' : 'anim'}`} onClick={onAppBtn}>Contact Us</button>
                </li>
        </ul>
      
    </div>
    <Menu isOpen={isOpen} onAppBtn={onAppBtn} toggleMenu={toggleMenu}></Menu>
    </div>
  );
};

export default Header;
