import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactBodymovin from "react-bodymovin";
import Header from "./components/Header";
import Menu from "./components/Menu";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Footer from "./components/Footer";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import ScrollToTop from "./components/ScrollToTop";
import Studio from "./pages/Studio";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  useEffect(() => {}, []);

  const onAppBtn = (event) => {
    //console.log(event,"event")
    const buttonId = event.target.id;
    console.log(currentPath, buttonId)
    if(currentPath==='/'+buttonId)
      return;
    // Navigate based on button ID
    switch (buttonId) {
      case "home":
        navigate("/"); // Navigate to home route
        break;
      case "about-us":
        navigate("/about"); // Navigate to about route
        break;
      case "contact-us":
        navigate("/contact-us"); // Navigate to about route
        break;
      case "privacy-policy":
        navigate("/privacy"); // Navigate to contact route
        break;
      case "terms":
        navigate("/terms"); // Navigate to contact route
        break;
      case "studio":
        navigate("/studio"); // Navigate to contact route
        break;
      case "nano":
        window.open("https://www.experiencenano.com/", "_blank");
        break;
      case "adjingo":
        window.open("https://www.2cimple.com/", "_blank");
        break;
      case "play-store":
        window.open(
          "https://play.google.com/store/apps/details?id=com.cimple.nano",
          "_blank",
        );
        break;
      case "app-store":
        window.open(
          "https://apps.apple.com/us/app/nano/id1387919403",
          "_blank",
        );
        break;
      case "mobiles":
        window.open("https://www.experiencenano.com/", "_blank");
        break;
      case "x-container":
        window.open("https://twitter.com/2cimple", "_blank");
        break;
      case "fb-container":
        window.open("https://www.facebook.com/2CimpleInc/", "_blank");
        break;

      default:
        break;
    }
  };
  return (
    <div className="App">

      <Header onAppBtn={onAppBtn} currentPath={currentPath} />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home onAppBtn={onAppBtn} />} />
        <Route path="/studio" element={<Studio />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        {/* <Route path="/adjingo" element={<Adjingo />} />
          <Route path="/nano" element={<Nano />} /> */}
      </Routes>
      <Footer onAppBtn={onAppBtn} currentPath={currentPath} />
    </div>
  );
}

export default App;
