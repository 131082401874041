import React from 'react';
import Banner from '../components/Banner';
import Content from '../components/Content';
import bg from '../assets/images/main-banner-bg.jpg'

const Home = ({ onAppBtn }) => {
  return (
    <div>
      <Banner 
        content={<div>
      WE ARE AN INTELLIGENT CONTENT <br />PERSONALIZATION PLATFORM & <br />TECHNOLOGY STUDIO
      </div>}
      bg={bg}
      />
      <Content onAppBtn={onAppBtn} />
    </div>
  );
};

export default Home;
