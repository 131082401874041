import React from 'react';
import { Link } from 'react-router-dom';

const Menu = ({isOpen, onAppBtn, toggleMenu}) => {
  console.log(isOpen)
const onBtnClick=(event)=>
  {
    //console(this);
   toggleMenu();
   onAppBtn(event);

    // onToggleMenu();
  }
  return (
    <nav className={`menu ${isOpen ? 'open' : ''}`} id="menu">
      <Link to="/" className="nav-link header-button" style={{ marginTop: '65px !important' }} onClick={onBtnClick}>Home</Link>
      <Link to="/studio" className="nav-link header-button" onClick={onBtnClick}>Studio</Link>
      {/* <Link to="/blog" className="nav-link header-button">Blog</Link> */}
      <Link  id="nano" className="nav-link header-button" onClick={onBtnClick}>Nano</Link>
      <Link to="/contact-us" className="nav-link header-button" onClick={onBtnClick}>Contact Us</Link>
    </nav>
  );
};

export default Menu;
