import React from "react";
import adjingoLogo from  "../assets/images/adjingo-logo.png";
import nanoLogo from  "../assets/images/nano-logo.png";
import mobiles from  "../assets/images/mobiles.png";
import appStore from  "../assets/images/app-store.png";
import playStore from  "../assets/images/play-store.png";
import client1Img from  "../assets/images/client-logo-1.png";
import client2Img from  "../assets/images/client-logo-2.png";
import client3Img from  "../assets/images/client-logo-3.png";
import client4Img from  "../assets/images/client-logo-4.png";
import client5Img from  "../assets/images/client-logo-5.png";
import blog1 from  "../assets/images/blog-1.png";
import blog2 from  "../assets/images/blog-2.png";
import blog3 from  "../assets/images/blog-3.png";
import clientImg from  "../assets/images/client-img.png";
import graph from  "../assets/images/graph.png";



import Lottie from "react-lottie";
import adjingo from "../assets/js/adjingo.json";

const Content = ({ onAppBtn }) => {
  const anim = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: adjingo,
  };
  return (
    <div className="content-container">
      <div className="row platform-row">
        <div
          className="col-sm-6"
          style={{
            position: "relative",
            cursor: "pointer",
            height: "auto",
            display: "flex",
          }}
        >
          <div className="col-8 platform-col font-3 text-xxl bold">
            <span>
              <div>ABOUT THE</div>
              <div style={{ letterSpacing: "3px" }}>PLATFORM</div>
            </span>
          </div>
          <div className="col platform-col">
            <span
              style={{
                height: "1px",
                borderBottom: "1px solid white",
                width: "96%",
                display: "inline-block",
              }}
            ></span>
          </div>
        </div>
        <div
          className="col-sm-6"
          style={{
            position: "relative",
            cursor: "pointer",
            height: "auto",
            textAlign: "left",
            fontSize: "12px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <div className="platform-text">
              <strong className="platform-heading font-3 text-m bold">
                The Platform
              </strong>
            </div>
            <div className="platform-text font-2 text-s italic">
              {" "}
              2Cimple has created a fully patented cloud-based video + image
              intelligent platform that converts passive content into
              personalized shopping content to actively engage consumers,
              monetize natively and enhance ROI.
            </div>
          </div>
          <div>
            <p />
            <div>
              <strong className="platform-heading font-3 bold text-m">
                Our Vision
              </strong>
            </div>
            <div className="platform-text font-2 text-s italic">
              Relevant content should find you at the right time and in the
              right location. It should provide you with what you want and when
              you want it ….... to maximize and 2Cimplify your experience.
            </div>
          </div>
        </div>
      </div>

      {/*Nano Adjingo  */}
      <div className="row" style={{ margin: 0, minHeight: "300px" }}>
        <div
          className="col-sm-6"
          style={{
            backgroundColor: "black",
            position: "relative",
            cursor: "pointer",
            height: "auto",
            minHeight: "300px",
          }}
          id="adjingo"
          onClick={onAppBtn}
        >
          <div>
            <div className="row">
              <div
                id="adjingo"
                className="col"
                style={{
                  textAlign: "center",
                  paddingTop: "15px",
                  cursor: "pointer",
                }}
                onClick={onAppBtn}
              >
                <img
                  src={adjingoLogo}
                  style={{ margin: "auto", width: "38%" }}
                  className="anim"
                  alt="Adjingo"
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col"
                style={{ textAlign: "center", padding: 0, margin: 0 }}
              >
                <div className="adjingo-txt">
                  <span>Interactive Hotspot</span>
                  <span>Puzzle</span>
                  <span>Dynamic Canvas</span>
                  <span>Engageable Image</span>
                  <span>Shoppable Video</span>
                </div>
              </div>
            </div>
            <div
              className="row padding-bottom"
              style={{ paddingBottom: "10px" }}
            >
              <Lottie options={anim} style={{ width: "60%" }}></Lottie>
            </div>
          </div>
          <div style={{ textAlign: "center", height: "100%", display: "none" }}>
            <img
              src={adjingoLogo}
              style={{ width: "43%", margin: "10px 0px 28px 0px" }}
              className="anim"
              alt="logo"
            />
      
          </div>
        </div>
        <div className="col-sm-6" style={{ backgroundColor: "#ff5258" }}>
          <div>
            <div className="row">
              <div
                id="nano"
                className="col"
                style={{
                  textAlign: "center",
                  paddingTop: "10px",
                  cursor: "pointer",
                }}
                onClick={onAppBtn}
              >
                <img
                  src={nanoLogo}
                  style={{ margin: "auto", width: "36%" }}
                  className="anim"
                  alt="Nano"
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col"
                style={{ textAlign: "center", padding: 0, margin: 0 }}
              >
                <img
                  id="mobiles"
                  src={mobiles}
                  style={{ width: "50%", cursor: "pointer" }}
                  onClick={onAppBtn}
                  className="anim"
                  alt="Group"
                />
              </div>
            </div>
            <div
              className="row padding-bottom"
              style={{ paddingBottom: "10px" }}
            >
              <div
                className="col"
                style={{ padding: 0, margin: 0, textAlign: "right" }}
              >
                <img
                  id="app-store"
                  src={appStore}
                  className="app-btns anim"
                  onClick={onAppBtn}
                  alt="App Store"
                />
              </div>
              <div
                className="col"
                style={{ padding: 0, margin: 0, textAlign: "left" }}
              >
                <img
                  id="play-store"
                  src={playStore}
                  className="app-btns anim"
                  onClick={onAppBtn}
                  alt="Play Store"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Measure */}
      <div className="row graph-section" style={{ margin: 0 }}>
        <div
          className="col-sm-6 graph-bg"
          style={{
            backgroundColor: "#ff5258",
            position: "relative",
            cursor: "pointer",
            height: "auto",
            display: "flex",
            alignItems: "center",
            minHeight:"200px",
          }}
        >
          <img src={graph} className="graph-image" />
        </div>
        <div className="col-sm-6" style={{ backgroundColor: "#fff" }}>
          <div>
            <div className="row">
              <div className="col">
                <div className="measure-heading font-3 text-xxl">
                  HOW WE
                  <br /> MEASURE UP
                </div>
                <div>
                  <p className="measure-text font-2 text-s italic">
                    Based on industry averages, our intelligent content
                    significantly outperforms traditional digital channels.
                    Whether it`s rich media banners, display ads, educational
                    messaging, or in-video interactive units, your content and
                    consumer interactivity will outshine others. We are here to
                    help you exceed your KPI goals, Return on Ad Spend and ROI
                    challenges, and assist you to surpass your targets.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Quote */}
      <div className="row" style={{ margin: 0 }}>
        <div
          className="col quote"
          style={{
            padding: "0",
          }}
        >
          <div
            style={{
              backgroundColor: "#0008",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "4vw",
            }}
          >
            <div>
              &quot;CONTENT SHOULD FIND THE CONSUMER,
              <br /> BE PERSONALIZED & AUTOMATED&quot;
            </div>
            <div
              className="font-2 text-s italic"
              style={{
                paddingTop: "20px",
                color: "lightgrey",
              }}
            >
              - Syed Athar Abbas, 2Cimple Founder
            </div>
          </div>
        </div>
      </div>
      <div
        className="row"
        style={{ margin: 0, padding: "4vw", backgroundColor: "white", display: "none" }}
      >
        <div className="row">
          <div className="col text-xxl bold">
            <p>FROM THE BLOG
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <div style={{ textAlign: 'left' }}>
              <div style={{ width: "100%", height: "15vw", margin: "10px 0", backgroundSize: "cover", backgroundImage: `url("${blog1}")` }}>

              </div>
              <p className="text-s bold">Excepteur sint occaecat.</p>
              <p className="text-s font-2">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.</p>
              <div> <button className="text-xs button-red" style={{ margin: 0 }}>
                READ MORE
              </button></div>

            </div>

          </div>
          <div className="col-sm">
            <div style={{ textAlign: 'left' }}>
              <div style={{ width: "100%", height: "15vw", margin: "10px 0", backgroundSize: "cover", backgroundImage: `url("${blog2}")` }}>

              </div>
              <p className="text-s bold">Excepteur sint occaecat.</p>
              <p className="text-s font-2">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.</p>
              <div> <button className="text-xs button-red" style={{ margin: 0 }}>
                READ MORE
              </button></div>

            </div>

          </div>
          <div className="col-sm">
            <div style={{ textAlign: 'left' }}>
              <div style={{ width: "100%", height: "15vw", margin: "10px 0", backgroundSize: "cover", backgroundImage: `url("${blog3}")` }}>

              </div>
              <p className="text-s bold">Excepteur sint occaecat.</p>
              <p className="text-s font-2">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.</p>
              <div> <button className="text-xs button-red" style={{ margin: 0 }}>
                READ MORE
              </button></div>

            </div>

          </div>

        </div>

      </div>
      {/* Client Talks */}
      <div className="row client" style={{ margin: 0, padding: "3vw 6vw" }}>
        {/* <div className="col-1 flex-mid-center text-xxxxl bold arrow">&lt;</div> */}
        <div className="col">
          <div className="row" style={{ display: "Flex", padding: "2vw" }}>
            {/* <div
              className="col-sm-4 flex-mid-center"
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <img src={clientImg} className="client-img"></img>
            </div> */}
            <div
              className="col-sm"
              style={{
                padding: "0",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <div className="font-3 text-xxl bold">
                  CLIENTS TALK ABOUT US
                </div>

                <div
                  className="font-2 text-s italic"
                  style={{
                    paddingTop: "10px",
                    color: "lightgrey",
                  }}
                >
                  Michigan Athletics was extremely interested in fan engagement
                  via a user`s News Feed versus relying on a user seeing a
                  Michigan Athletics (i.e. Michigan Basketball) Facebook status
                  update in their News Feed, and then relying on the user to
                  click-thru, and complete the engagement. The 2Cimple software
                  eliminates multiple variables to allow for a more seamless
                  user-flow and opportunity for fans to engage.
                </div>
                <div
                  className="font-3 text-s bold"
                  style={{ textAlign: "center", paddingTop: "2vw" }}
                >
                  Jordan Maleh
                </div>
                <div
                  className="font-2 text-s italic"
                  style={{
                    paddingTop: "2px",
                    color: "lightgrey",
                    textAlign: "center",
                  }}
                >
                  Sr. Director, Marketing @ University of Michigan
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-1 flex-mid-center text-xxxxl bold arrow">&gt;</div> */}
      </div>

      {/* Subscribe  */}
      <div
        className="row"
        style={{ margin: 0, padding: "6vw", backgroundColor: "black" }}
      >
        <div
          className="col-sm-5 flex-mid-center text-xxl bold"
          style={{ textAlign: "left", color: "white" }}
        >
          SUBSCRIBE
          <br />
          to our NEWSLETTER
        </div>
        <div
          className="col-sm-7"
          style={{
            padding: "0",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="font-3 text-md bold">
              <input
                type="text"
                placeholder="Email Address"
                className="text-s"
                style={{
                  border: "1px solid #ff555b",
                  width: "60%",
                  padding: "6px",
                  color: "white",
                  backgroundColor: "transparent",
                }}
              ></input>
              <span>
                <button className="text-xs button-red" style={{}}>
                  SUBSCRIBE
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Client Icon */}
      <div
        className="client-icons"
        style={{
          backgroundColor: "#0000001C",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="inner-client-icons">
          <div
            style={{
              fontSize: "2em",
              textDecoration: "underline",
              marginBottom: "30px",
              textAlign: "center",
            }}
          >
            OUR CLIENTS
          </div>
          {}

          <div className="row">
            <div className="col">
              <img src={client1Img} alt="Client 1" />
            </div>
            <div className="col">
              <img src={client2Img} alt="Client 2" />
            </div>
            <div className="col">
              <img src={client3Img} alt="Client 3" />
            </div>
            <div className="col">
              <img src={client4Img} alt="Client 4" />
            </div>
            <div className="col">
              <img src={client5Img} alt="Client 5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
