import React from "react";
import twitter from "../assets/js/x.json";
import fb from "../assets/js/FB.json";
import ReactBodymovin from "react-bodymovin";
import Lottie from "react-lottie";
import { useLocation } from "react-router-dom";

const Footer = ({ onAppBtn, currentPath }) => {
  const fbAnim = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: fb,
  };
  const twitterAnim = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: twitter,
  };
  // const location = useLocation();
  // const currentRoute = location.pathname;
  console.log(currentPath, "cp");
  return (
    <div className="footer">
      {currentPath != "/contact-us" ? (
        <div className="footer-social">
          <div
            id="fb-container"
            className="animations-container"
            onClick={onAppBtn}
          >
            <Lottie options={fbAnim} />
          </div>
          <div
            id="x-container"
            className="animations-container"
            onClick={onAppBtn}
          >
            <Lottie options={twitterAnim} />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="footer-bottom">
        <div className="footer-bottom-inner">
          <div className="row" style={{ width: "100%", margin: 0 }}>
            <div className="col-sm" style={{ textAlign: "center" }}>
              ® 2024 2CIMPLE, INC. ALL RIGHTS RESERVED.
            </div>
            <div className="col-sm" style={{ textAlign: "center" }}>
              <span
                id="privacy-policy"
                className={`footer-bottom-btns ${currentPath == "/privacy" ? "page-selected" : "anim"}`}
                onClick={onAppBtn}
              >
                PRIVACY POLICY
              </span>{" "}
              |{" "}
              <span
                id="terms"
                className={`footer-bottom-btns ${currentPath == "/terms" ? "page-selected" : "anim"}`}
                onClick={onAppBtn}
              >
                TERMS OF USE
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
