import React from "react";
import Banner from "../components/Banner";
import bg from "../assets/images/contact-us.jpg"

const ContactUs = () => {
  return (
    <div>
      <Banner
        bg={bg}
        content={
          <div className="banner-text">
            HELLO
            <br />
            <br />
            We’d Love To Hear From You
          </div>
        }
        height={"40vw"}
      />
      <div>
        <div style={{ padding: "30px 4%" }}>
          <div className="row" style={{ margin: "0" }}>
            <div
              className="col-sm-6"
              style={{
                position: "relative",
                height: "auto",
                justifyContent: "center",
                borderRight: "2px solid",
              }}
            >
              <div style={{ textAlign: "center", height: "100%" }}>
                <div>
                  <div>
                    <p
                      style={{
                        textAlign: "center",
                        letterSpacing: "1px",
                        margin: "0",
                      }}
                    >
                      <span style={{ color: "white" }}>
                        <b style={{ color: "#ff5a60" }}>DALLAS</b>
                      </span>
                    </p>
                  </div>
                  <div>
                    <h3 style={{ textAlign: "center" }}>
                      3200 14th Street Suite # 414, Plano, TX, 75074
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div style={{ textAlign: "center", height: "100%" }}>
                <div>
                  <p
                    style={{
                      textAlign: "center",
                      letterSpacing: "1px",
                      margin: "0",
                    }}
                  >
                    <span>
                      <b style={{ color: "#ff5a60" }}>KARACHI</b>
                    </span>
                  </p>
                </div>
                <div>
                  <h3 style={{ textAlign: "center" }}>
                    Suite # 704, 7th Floor Block 3, Dilkusha Forum, Plot #7 Main
                    Tariq Road, P.E.C.H.S Karachi, Pakistan{" "}
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div style={{ textAlign: "center", padding: "10px 0" }}>
            <h3>
              Reach out to us on{" "}
              <a style={{ color: "#ff5a60" }} href="mailto:info@2cimple.com">
                info@2cimple.com
              </a>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
