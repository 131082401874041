import React, { useState } from 'react';

const MenuIcon = ({toggleMenu, isOpen}) => {
    console.log(toggleMenu, isOpen)
  /* const [isOpen, setIsOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };
 */
  return (
    <div className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default MenuIcon;
