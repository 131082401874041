import React from "react";
import ReactBodymovin from "react-bodymovin";
import Lottie from "react-lottie";
import Down from "../assets/js/down.json"
import Shine from "../assets/js/Shine.json";


const Banner = ({ bg, content, height, brightness=0.7}) => {
  console.log(content);
  const anim = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: Shine,
  };
  const animArrow = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: Down,
  };
  return (
    <div className="top-banner fade-anim"
      
      style={{ height: height }}
    >
    <div className="top-banner-inner"
      style={{ backgroundImage: `url('${bg}')`, filter:`brightness(${brightness})`, backgroundPosition:"center" }}
    ></div>
    
      <div className="col-lg-12 animation-container" id="animation-container">
        <Lottie options={anim} />
      </div>
      <div className="banner-text bold">{content}</div>
      <div className="down-button"><Lottie options={animArrow} /></div>

    </div>
  );
};

export default Banner;
