import React from 'react';
import Banner from '../components/Banner';
import bg from "../assets/images/terms.jpg"



const Privacy = () => {
    return (
        <div>
            <Banner
                bg={bg}
                content={
                    <div>
                        PRIVACY POLICY
                        <br />
                        <br />
                        Your Privacy Is Important To Us
                    </div>
                }
                height={'40vw'}

            />
            <div>
            <div style={{ padding: '10px 6%' }}>
            <div style={{ fontSize: '16px', marginTop: '0px', marginBottom: '15px', textAlign:"left" }}>
                <h3 style={{ textAlign: 'left', fontSize: '25px', lineHeight: '28px', fontWeight:"bold"}}>
                    2CIMPLE, INC. DIGITAL PROPERTIES PRIVACY POLICY
                </h3>
                <p>Date of Last Revision: December 1, 2023</p>
                <p>This privacy policy applies to the 2Cimple, Inc.’ Properties located at adjingo.2cimple.com, and the Nano. Properties located at www.experiencenano.and all other websites and apps developed by 2Cimple, Inc. (the “Properties”). The purpose of this privacy policy is to disclose to you what information we may collect, how we may collect it, with whom we may share it, and certain other matters related to such information, including the choices you have regarding our collection of information and our use and disclosure to other parties of information we may have collected from you.</p>
                <p>When this privacy policy uses the term “personally identifiable information” or “personal information,” we mean information that identifies a particular individual, such as your full name, street address or email address. Please read the information below to learn more about our data collection policies and practices.</p>
                <ul style={{ marginLeft: '15px' }}>
                            <li>Types of Information That May Be Collected</li>
                            <li>How Information May Be Collected</li>
                            <li>How We Use the Information Collected</li>
                            <li>With Whom the Information May Be Shared</li>
                            <li>Security</li>
                            <li>Blogs, Forums, and Message Boards</li>
                            <li>Links to Other Properties</li>
                            <li>A Special Note for Parents Concerning Privacy</li>
                            <li>Notification of Changes and Your Acceptance Of The Privacy Policy</li>
                            <li>Correcting/Updating Personal Information</li>
                            <li>Your California Privacy Rights</li>
                            <li>Questions About This Privacy Policy</li>
                        </ul>
                        <p><strong>1. TYPES OF INFORMATION THAT MAY BE COLLECTED</strong></p>
                        <p>The following are the types of information we may collect:</p>
                        <p>Information You Provide Us: In the course of using the Properties, we may ask you to provide us with certain information, including contact information, such as your name, mailing address, email address, phone number, fax number, unique identifiers, such as your user password, photo and video, Facebook user-name, Twitter user name, demographic information, such as your age range, gender, education, household income, marital status, financial information, such as your credit card number or similar information.</p>
                        <p>Site Usage Information: We may receive and store certain types of internet usage information whenever you visit the Properties, such as your click-stream data and your IP address. See IP Addresses and Click Stream Data section below.</p>
                        <p>Email Communications: When you open emails from us, we may receive a confirmation noting you opened the email, if your computer supports such capabilities. We also may keep track of what emails we send you and what emails you may be receiving from our subsidiaries and affiliates. Additionally, we keep a record of what communications you have opted to receive or not to receive.</p>
                        <p><strong>2. HOW INFORMATION MAY BE COLLECTED</strong></p>
                        <p>We may collect information from you in several different areas on the Properties, including but not limited to those described below:</p>
                        <p>Registration/Check In: You may be asked to complete a registration/check in form and provide personally identifiable information; you may also be asked to choose a user ID and password in order to take advantage of certain features the Properties may choose to offer, such as notifications, newsletters or contests.</p>
                        <p>Newsletters and Properties Emails: We may offer free newsletters to share information with you about the Properties, about us and our affiliates and subsidiaries, or about our marketing partners and sponsors. You may receive a newsletter either by registering for a particular newsletter or in conjunction with a special feature or membership for which you have registered on the Properties. Participation is voluntary and you will have the opportunity to decide whether or not to disclose information to the Properties. You may also cancel your free email subscription by following the instructions provided on the Properties or in the newsletter or email. Please send an email to support@2cimple.com with Subject: Opt Out. We do not share your email address with any third parties for marketing purposes.</p>
                        <p>Contests or Sweepstakes: We may periodically operate contests and sweepstakes through the Properties that may require registration or the submission of personal information. Participation is voluntary and you will have the opportunity to decide whether or not to disclose information to the Properties. Your personally identifiable information may be used to contact you for winner notification, prize delivery confirmation or other related purposes, (see the rules of each contest for details about how your personal information will be used). We may also ask your permission to allow us to publicly post some of your information on the Properties or other digital and social media sites, such as on a winner’s page, in the event you win a contest or sweepstakes. In some instances, a third-party sponsor may operate or host a contest or sweepstakes on the Properties and collect your information. In those cases, where we are not the operator or host of the contest or sweepstakes, we do not collect your information and we have no control over the information collected from you. Accordingly, we urge you to review the sponsor’s privacy policy applicable to the contest or sweepstakes before participating.</p>
                        <p>Surveys or Voting: From time-to-time we may request personally identifiable information from you in conjunction with a survey or voting poll. Participation is voluntary and you will have the opportunity to decide whether or not to disclose information to the Properties.</p>
                        <p>Games: When you register to play games on our Properties, you may be asked to provide personally identifiable information.</p>
                        <p>Cookies: As with many Properties, our Properties use a technology called “cookies.” A cookie is a unique, random number that is stored in the browser on your computer. The cookie doesn’t actually identify you, just the computer that is used to visit the site. Cookies automatically identify your browser to the Properties whenever your computer is used to visit the Properties. Cookies also let us know how many people visit our Properties and where visitors go on the site. In addition, cookies can let us know non-personally identifiable information, like what web browser is being used to access the Properties. For registered users, cookies can make using the Properties easier for you by saving information such as your passwords or preferences. By tracking how and when you use the Properties, cookies help us determine which areas are popular and which are not. Many improvements and updates to the Properties are based on data obtained from cookies. Accepting cookies allows you, among other things, to personalize your experience on the Properties. If you do not want information collected through the use of cookies, your browser should contain an option that allows you to disable or delete cookie data at any time. Some areas of the Properties, however, may not provide you with a personalized experience if you have disabled the use of cookies.</p>
                        <p>IP Addresses and Click-Stream Data: We may collect IP addresses and/or click-stream data. An IP address is a number associated with the service through which you access the Internet, like your ISP (Internet service provider), your company, or your university. Standing alone, your IP address is not personally identifiable. At times, we also use IP addresses for purposes of system administration and to report aggregate information to our sponsors, for example regarding the frequency with which our guests visit various parts of our sites. We may combine this information with personally identifiable information. Click-stream data is information collected by our computers when you request Web pages from the Properties. Click-stream data may include such information as the page served, the time, the source of the request, the type of browser making the request, the preceding page view and other such non-personal information.</p>
                        <p>When analyzed, this data helps us analyze how visitors arrive at the Properties, what type of content is most popular, what type of visitors in the aggregate are interested in particular kinds of content.</p>
                        <p>Transaction Information: If you conduct a financial transaction with our Properties, we will ask you to complete an order form that will request certain information from you, including contact information, demographic information and financial information. We will use this information to complete the transaction that you have requested, i.e., to deliver the information, products or other services that you order, and to invoice you. For your protection, such information is collected and stored through encrypted means. In some instances, a third-party sponsor may operate or host an ecommerce store or redirect from the Properties and collect your information. In those cases, where we are not the operator or host of the ecommerce store, we do not collect your information and we have no control over the information collected from you. Accordingly, we urge you to review the sponsor’s privacy policy applicable to the ecommerce store before making a purchase.</p>
                        <p><strong>3. HOW WE USE THE INFORMATION COLLECTED</strong></p>
                        <p>As applicable, the information you provide to us may be used to:</p>
                        <ul style={{ marginLeft: "15px"}}>
                            <li>Contact you about the Properties;</li>
                            <li>Contact you relating to your membership status;</li>
                            <li>Confirm or fulfill an order you have made;</li>
                            <li>Monitor or improve the use of the Properties;</li>
                            <li>Customize the advertising and content you see;</li>
                            <li>Monitor compliance with our terms and conditions of use of the Properties;</li>
                            <li>Provide special offers to you from us and/or our respective affiliates and subsidiaries and other third parties;</li>
                            <li>Send you promotional material on behalf of our marketing partners and/or their respective affiliates and subsidiaries and other third parties.</li>
                        </ul>
                        <p><strong>4. WITH WHOM THE INFORMATION MAY BE SHARED</strong></p>
                        <p>At times, as described below, we may share your personal information with others for various purposes:</p>
                        <p>Agents: We employ other businesses and individuals to perform functions on our behalf. Examples may include hosting our web servers, analyzing data, providing marketing assistance, and providing customer service. These other businesses and individuals will have access to the personal information needed to perform their functions, but they may not use that information for any other purpose.</p>
                        <p>Aggregate Information: We may share aggregate information, such as demographics and site usage statistics, with advertisers, sponsors or other organizations.</p>
                        <p>Opt-In Communications: Throughout the Properties, you may be given the opportunity to receive communications from our third party partners. If you opt-in to receive such communications, we will share your contact information with the applicable third party partners.</p>
                        <p>Administrative or Legal Process: Your personal information may be transferred to third parties without notice to you, for any of the following reasons: (i) to comply with a legal requirement, judicial proceeding, court order, or legal process served on us, (ii) to investigate a possible crime, such as fraud or identity theft; (iii) in connection with the sale, purchase, merger, reorganization, liquidation or dissolution of 2Cimple, Inc.; (iv) when we believe it is necessary to protect the rights, property, or safety of 2Cimple, Inc. or other persons, or (v) as otherwise required or permitted by law.</p>
                        <p>Business Transfer: In the event that we, or any portion of our assets, are acquired or we undergo another transaction in which your personal information is one of the assets transferred, your personal information may be one of the transferred assets.</p>
                        <p><strong>5. SECURITY</strong></p>
                        <p>This Properties have security measures in place to help protect against the loss, misuse, and alteration of the information under our control. We employ reasonable security measures consistent with standard industry practice for information collected through the Properties. While we cannot guarantee that loss, misuse or alteration to information will not occur, we make every effort to prevent such occurrences.</p>
                        <p><strong>6. BLOGS, FORUMS, AND MESSAGE BOARDS</strong></p>
                        <p>We may make blogs, chat rooms, forums, message boards, and news groups available to you through the Properties. Please remember that any information you disclose in these areas becomes public information and you should exercise caution when deciding to disclose your personal information.</p>
                        <p><strong>7. LINKS TO OTHER PROPERTIES</strong></p>
                        <p>You should be aware that when you are on the Properties you could be directed to other sites beyond our control. For example, if you click on certain links, you will leave the Properties and move to a different website. These other websites may send their own cookies to visitors, collect data, or solicit personal information. The privacy policies of these other websites may be significantly different from this privacy policy. We are not responsible for the privacy practices of these other websites and cannot guarantee the security of any of your personal information collected there.</p>
                        <p><strong>8. A SPECIAL NOTE FOR PARENTS CONCERNING PRIVACY</strong></p>
                        <p>We do not collect any personally identifiable information from anyone who we know to be under the age of 13. Where appropriate, we will instruct children not to submit such information to the Properties or in response to advertisements. If a child has provided us with personally identifiable information, a parent or guardian of that child may contact us at support@2cimple.com if they would like this information deleted from our records. We will use reasonable efforts to delete the child’s information from our databases.</p>
                        <p><strong>9. NOTIFICATION OF CHANGES AND YOUR ACCEPTANCE OF THE PRIVACY POLICY</strong></p>
                        <p>By using the Properties, you signify your agreement to the terms of this privacy policy. If we decide to change or update this privacy policy, we will post the changes on the Properties so that you will always be aware of what information we collect, use and distribute. Your continued use of the Properties constitutes your acceptance of any revised privacy policy.</p>
                        <p><strong>10. CORRECTING/UPDATING PERSONAL INFORMATION</strong></p>
                        <p>If you have any questions regarding our privacy policy, please contact us at:</p>
                        <p><strong>2Cimple, Inc.</strong></p>
                        <p><strong>Address:</strong> 123 Privacy Way, Privacy City, PC 12345</p>
                        <p><strong>Email:</strong> support@2cimple.com</p>
                        <p><strong>Phone:</strong> (123) 456-7890</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Privacy;



