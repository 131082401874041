import React from 'react';
import '../Studio.css'; // Import the CSS file
import Banner from "../components/Banner";

import bg from "../assets/images/studio.jpg"
import nestle from "../assets/images/nestle.png"
import dove from "../assets/images/dove.png"
import burberry from "../assets/images/burberry.png"
import express from "../assets/images/express.png"
import neiman from "../assets/images/neiman.jpg"

const Studio = () => {
  return (
    <div id="content" className="full-width">
      <Banner
        bg={bg}
        content={
          <div className="banner-text">
              <span>OUR SHOWCASE</span>
          
                  <h1 className="font-large">
                    
                    <br />
                    <br />
                  
                  </h1>
          </div>
        }
        height={"40vw"}
      />
        <div className="post-content">
        <div className="text-m bold" style={{whiteSpace:"wrap", padding:"0vw"}}>
                    Below are a few snippet examples of our patented-platform capability. 
                    You will see shopper marketing, brand engagement, lead generation, click-to-clip couponing, and much more. These examples were specially curated for specific brands using our cognitive AGI intelligence engine. Enjoy!
                  </div>
            <div className="row post">
              <div className="col-sm-6 text-box margin-15">
                <div className="text-center">
                  <img
                    src={neiman}
                    alt="Neiman"
                  />
                  <p className="text-red text-l bold">SHOPPABLE IMAGE</p>
                  <p className="text-dark text-m">
                    Image overlay and shoppable imagery is a breeze with our platform. Instantly convert static assets to monetized assets in seconds.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 margin-15">
                <video className="video-container" controls>
                  <source src="//s3.amazonaws.com/adjingo.2cimple.com/2cimple_site_assets/neiman_markus.mp4" type="video/mp4" />
                </video>
              </div>
            </div>

              <div className="row post">
                <div className="col-sm-6 text-box margin-15">
                  <div className="text-center">
                    <img
                      src={express}
                      alt="Express"
                    />
                    <p className="text-red text-l bold">SHOPPABLE VIDEO</p>
                    <p className="text-dark text-m">
                      Customers can instantly shop the look from the content with our patented interactive overlays. This is quite literally the easiest way to “Get The Look” in the beauty and fashion industries. – A true game changer.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 margin-15">
                  <video className="video-container" controls>
                    <source src="//s3.amazonaws.com/adjingo.2cimple.com/2cimple_site_assets/express_desktop.mp4" type="video/mp4" />
                  </video>
                </div>
              </div>
              <div className="row post">
                  <div className="col-sm-6 text-box margin-15">
                    <div className="text-center">
                      <img
                        src={dove}
                        alt="Dove"
                      />
                      <p className="text-red text-l bold">INTERACTIVE SWEEPS</p>
                      <p className="text-dark text-m">
                        On Mother’s Day, we partnered with Dove to allow customers a chance to win an iPhone for their moms. Customers could upload their photos and videos to create a specialized message that melted the hearts of moms around the globe.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 margin-15">
                    <video className="video-container" controls>
                      <source src="//s3.amazonaws.com/adjingo.2cimple.com/2cimple_site_assets/dove_croped.mp4" type="video/mp4" />
                    </video>
                  </div>
                </div>
                <div className="row post">
                  <div className="col-sm-6 text-box margin-15">
                    <div className="text-center">
                      <img
                        src={burberry}
                        alt="burberry"
                      />
                      <p className="text-red text-l bold">DYNAMIC CANVAS</p>
                      <p className="text-dark text-m">
                      What have major brands and retailers wanted for a long time? Shoppable Video! We have cracked the code on video and images collapsing the shopping funnel and bringing the point of sale at the zero moment of truth.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 margin-15">
                    <video className="video-container" controls>
                      <source src="//s3.amazonaws.com/adjingo.2cimple.com/2cimple_site_assets/burberry.mp4" type="video/mp4" />
                    </video>
                  </div>
                </div>

                

                <div className="row post">
                  <div className="col-sm-6 text-box margin-15">
                    <div className="text-center">
                      <img
                        src={nestle}
                        alt="nestle"
                      />
                      <p className="text-red text-l bold">BRAND ENGAGEMENT</p>
                      <p className="text-dark text-m">
                      We’ve all seen those Tastemade videos of amazing recipes, with our technology not only can you do a dry-run of the instructions but it helps reinforce your recipe memory as well. Nestle’s MilkPak partnered with us to create “Banoffee Pie” and timed participants to win free product.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 margin-15">
                    <video className="video-container" controls>
                      <source src="//s3.amazonaws.com/adjingo.2cimple.com/2cimple_site_assets/milkpak.mp4" type="video/mp4" />
                    </video>
                  </div>
              
                  <p className="text-red text-l bold">
                  <br />
                  <br />
                     GAMIFICATION</p>
                  <p className="text-dark text-m">
Keep your customers eyes on your content longer. Gamification has proven to have a lasting effect on consumers memory when it comes to branding and content. With our platform we can create simple games like the ones you see below or hi-tech VR and 360º video content.
                </p></div>
              
        </div>
      </div>
  );
};

export default Studio;
